<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <div v-if="user_role == 'assistant'">
              <h2 class="content-header-title float-left pr-1 mb-0">
                {{ role }}
              </h2>
            </div>
            <div v-else>
              <h2 class="content-header-title float-left pr-1 mb-0">
                {{ user_role.charAt(0).toUpperCase() + user_role.slice(1) }}
              </h2>
            </div>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                  <span aria-current="location"> Edit </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <validation-observer ref="simpleRules">
      <b-form @submit="updateUser" method="post">
        <b-card>
          <h3 class="mb-2 top-header">
            <feather-icon @click="goBack" icon="ArrowLeftIcon" size="24" />
            Basic Information
          </h3>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Name<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="formData.name"
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
							<b-form-group label="Email" label-for="email">
								<validation-provider
									#default="{ errors }"
									name="Email"
									rules="required"
								>
									<b-form-input
										type="email"
										v-model="formData.email"
										name="email"
										placeholder="email"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col> -->
            <b-col md="6">
              <b-form-group label="Status" label-for=" status">
                <div class="d-flex mt-1">
                  <b-form-radio
                    v-model="formData.status"
                    name="status"
                    value="Active"
                    class="mr-1"
                    >Active</b-form-radio
                  >
                  <b-form-radio
                    v-model="formData.status"
                    name="status"
                    value="Inactive"
                    >InActive</b-form-radio
                  >
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Phone No<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  :rules="{
                    required: true,
                    integer: true,
                    min: 10,
                  }"
                >
                  <b-form-input
                    type="phone"
                    v-model="formData.phone_no"
                    name="phone_no"
                    placeholder="Enter Phone No"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group class="mb-1">
                <template v-slot:label> Profile Image </template>
                <b-form-file
                  v-model="formData.user_image"
                  plain
                  @change="onFileChange"
                />
              </b-form-group>
              <div v-if="url">
                <div class="profile-image">
                  <img :src="url" height="90" />
                  <div class="remove-image">
                    <feather-icon
                      icon="TrashIcon"
                      size="16"
                      class="deleteIcon"
                      @click="resetImage"
                    />
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <!-- address information -->
          <h3 class="mb-2 mt-2 top-header">Address Information</h3>
          <b-row>
            <b-col md="12">
              <b-form-group label="Address" label-for="Address">
                <b-form-textarea
                  type="text"
                  v-model="formData.address"
                  name="address"
                  placeholder=""
                  rows="3"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="State" label-for="state">
                <v-select
                  v-model="formData.state"
                  :options="stateList"
                  label="state_name"
                  @input="getDistrict"
                  :clearable="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="District" label-for="district">
                <v-select
                  v-model="formData.district"
                  :options="districtList"
                  label="district_name"
                  @input="getCity"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="City" label-for="city">
                <v-select
                  v-model="formData.city"
                  :options="cityList"
                  label="city_name"
                />
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
          </b-row>
          <div class="mb-5">
            <b-button
              type="submit"
              variant="primary"
              value="Submit"
              class="mr-1"
            >
              Submit
            </b-button>
            <!-- <input type="submit" value="Submit" /> -->
          </div>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import * as Vue from "vue";
import axiosIns from "@/libs/axios";
import VueAxios from "vue-axios";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  BFormSelect,
  BFormDatepicker,
  BFormRadio,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { BASE_URL } from "@core/common/constants";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BFormRadio,
    BFormSelect,
    BFormDatepicker,
    flatPickr,
    vSelect,
    Logo,
    BFormFile,
  },
  props: {
    permissionName: {
      type: String,
    },
  },
  data() {
    return {
      stateList: [],
      districtList: [],
      cityList: [],
      url: null,
      formData: {
        user_image: null,
        name: null,
        email: null,
        user_name: null,
        password: null,
        status: null,
        phone_no: null,
        address: null,
        selectedState: null,
        selectedDistrict: null,
        selectedCity: null,
      },
      user_role: window.location.pathname.split("/")[2],
      role: "Assistant Manager",
    };
  },
  created: function () {
    this.getState();
    this.getUserId();
  },
  methods: {
    getState() {
      axiosIns
        .get(`web/get-state-list`)
        .then((response) => {
          this.stateList = response.data.state_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    getDistrict(event) {
      axiosIns
        .get(`web/get-district-list?state_id=${event.id}`)
        .then((response) => {
          this.districtList = response.data.districts_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    getCity(event) {
      axiosIns
        .get(`web/get-city-list?district_id=${event.id}`)
        .then((response) => {
          this.cityList = response.data.city_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    getUserId() {
      const userId = this.$route.params.userId;
      axiosIns
        .get(`web/users/${userId}`)
        .then((response) => {
          this.formData = response.data.user;
          this.formData.selectedState = response.data.user.state;
          this.formData.selectedDistrict = response.data.user.district;
          this.formData.selectedCity = response.data.user.city;
          this.formData.user_image = response.data.user.user_image.file_name;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    updateUser(e) {
      e.preventDefault();
      let data = new FormData();
      data.append("id", this.$route.params.userId);
      let form_data = this.objectToFormData(this.formData, data);

      axiosIns
        .post(`web/update-user`, form_data)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "success",
              text: `Data Updated Successfully `,
            },
          });
          this.$router.push({
            path: `/apps/${this.user_role.toLowerCase()}/list`,
          });
        })
        .catch((error) => {
          error;
        });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    resetImage() {
      this.url = "";
      this.formData.user_image = null;
    },
  },
  watch: {
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.top-header {
  border-bottom: 1px solid #38c06c;
  padding: 1rem 0rem;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
